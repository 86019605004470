import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { getSpectrumTypes } from '../services/Spectrum'

interface ISpectrumTypeProps {
    onChange: (e: any) => void;
    selected?: string;
    disabled: boolean;
}

function SpectrumTypeControl(props: ISpectrumTypeProps) {

    let listItem = Object.keys(getSpectrumTypes()).map((key, index) => { return (<MenuItem key={index} value={key}>{key.replace('_', ' ')}</MenuItem>)});

    return (<Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
            <InputLabel id="spectrum-type-selector-label-header">Spectrum Type</InputLabel>
            <Select
                labelId="spectrum-type-selector-label"
                id="spectrum-type-selector-id"
                label="Spectrum Type"
                value={props.selected ? props.selected : ''}
                onChange={props.onChange}
                disabled={props.disabled}
            >
                {listItem}
            </Select>
        </FormControl>
    </Box>
    );

}
export default SpectrumTypeControl;