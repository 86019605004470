import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { Item } from '../styles';

function ProgressControl() {
    return (<Item style={{
        padding: '3em',
        top: '50%',
        left: '50%',
        marginTop: '-10em',
        marginLeft: '-7em',
        position: 'fixed',
        backgroundColor: '#d5f5f5',
        zIndex: '999',
    }}><CircularProgress size={100} /></Item>
    );
}
export default ProgressControl;


