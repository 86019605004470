
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import ISpectrumPreviewModel from '../schemas/Spectrum/ISpectrumPreviewModel';

interface IProps {
    svgContent?: string,
    isMoleculeInContainer: boolean,
    moleculeId?: string,
    link: string,
    inline?: boolean,
    backToPage?: string,
    spectrumPreview?: ISpectrumPreviewModel,
    tooltip?: string,
    moleculeString?: string,
    mini?: boolean, 
}

const MoleculeView: React.FC<IProps> = (props) => {
    const svgString = encodeURIComponent(props.svgContent ? props.svgContent : '');
    const dataUri = `url("data:image/svg+xml,${svgString}")`;
    const phrase = 'Click below to open Ketcher';

    useEffect(() => {
        let moleculeContainers: any = Array.from(document.getElementsByClassName('mol-container'));
        moleculeContainers.map((moleculeContainer: any) => {
            moleculeContainer.style.backgroundSize = 'contain';
            moleculeContainer.style.backgroundRepeat = 'no-repeat';
            moleculeContainer.style.backgroundPositionY = 'center';
            moleculeContainer.style.margin = '1.5em';
        });

        let moleculeInlines: any = Array.from(document.getElementsByClassName('mol-inline'));
        moleculeInlines.map((moleculeInline: any) => {
            moleculeInline.style.backgroundSize = 'contain';
            moleculeInline.style.backgroundRepeat = 'no-repeat';
            moleculeInline.style.backgroundPositionY = 'center';
            moleculeInline.style.margin = '0em';
        });

    }, [props.svgContent]);


    const emptyContainer = () => {
        if (!props.isMoleculeInContainer) return (<span>Draw Structure Here</span>)
    }

    const phraseAbove = (<div style={{ marginTop: '-1em', color: 'chocolate', borderWidth: '1px', borderColor: 'chocolate', borderStyle: 'solid' }}>{phrase}</div>);


    const MW = (<div className={props.hasOwnProperty('inline') ? 'mol-inline' : 'mol-container'} 
                     style={{ height: props.mini ? '100px' :'200px', width: props.mini ? '100px' : '200px', alignItems: 'center', background: dataUri }}>
        {emptyContainer()}
    </div>);


    if (props.moleculeId || props.link)
        return (
            props.link ?
                <div>
                    {phraseAbove}
                    <Link to={{
                        pathname: props.link,
                        state: {
                            spectrumPreview: props.spectrumPreview
                        }
                    }}>
                        <Tooltip title={props.tooltip ? props.tooltip : 'Click here to draw molecule'}>
                            {MW}
                        </Tooltip>
                    </Link>
                    {props.moleculeString &&
                        <Tooltip title={'Copy molecule'}>
                            <ContentCopyIcon onClick={() => { navigator.clipboard.writeText(props.moleculeString as string) }} 
                                             style={{verticalAlign:'right', marginRight: '0.3em', float: 'right', cursor: 'pointer'
                        }} color="primary"/>
                        </Tooltip>


                    }

                </div>
                :
                <div>
                    {props.link && phraseAbove}
                    <Link to={'/exact-search/' + props.moleculeId}>
                        <Tooltip arrow title='click to open data for particular molecule'>
                            {MW}
                        </Tooltip>
                        
                    </Link>
                </div>
        );
    return MW;
};

export default MoleculeView;