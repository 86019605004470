import React from "react";

function Doi(id?: string, title?: string, volume: string = '', pages: string = '') {
  let address = pages;
  if (volume){
    address = address + ', ' + volume;
  }
  if (address){
    title = title + ', ' + address;
  }

  return (<a href={'https://doi.org/' + id} target="_blank">{title ? title : 'https://doi.org/' + id}</a>);
}

function DoiShort(id?: string, title?: string) {
  return (<a href={'https://doi.org/' + id} target="_blank">{title ? title : id}</a>);
}

const gotoDoi = (doi: string) => window.open('https://doi.org/' + doi);
export default { Doi, DoiShort, gotoDoi };
  