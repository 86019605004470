import React from 'react';
import { Switch, Redirect } from "react-router-dom";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import MainPage from './pages/MainPage';
import HelpPage from './pages/HelpPage';
import PremiumPage from './pages/PremiumPage';
import LoginPage from './pages/Login/LoginPage';
import SubSearchPage from './pages/Compound/SubSearchPage';
import CompoundSearch from './pages/Compound/CompoundSearch';
import RestorePage from './pages/Login/RestorePage';
import RegisterPage from './pages/Login/RegisterPage';
import ExactSearchPage from './pages/Compound/ExactSearchPage';
import PrivacyPolicyPage from './pages/Login/PrivacyPolicyPage';
import SpectrumPage from './pages/Spectrum/SpectrumPage';
import MoleculeEditorPage from './pages/Compound/MoleculeEditorPage';
import ConfirmationPage from './pages/Login/ConfirmationPage';
import PersonalInfoPage from './pages/Login/PersonalInfoPage';
import SearchHistoryPage from './pages/History/SearchHistoryPage';
import PublicationSearchPage from './pages/PublicationSearchPage';
import SpectrumSearchPage from './pages/Spectrum/SpectrumSearchPage';
import SearchOperationPage from './pages/History/SearchOperationPage';
import SpectrumComparePage from './pages/Spectrum/SpectrumComparePage';
import RestoreConfirmationPage from './pages/Login/RestoreConfirmationPage';
import SpectrumSearchResultPage from './pages/Spectrum/SpectrumSearchResultPage';

import theme from "./theme";

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/compound-search" >
            {(props: any) => <CompoundSearch {...props} />}
          </Route>

          <Route path="/editor/:session" >
            {(props: any) => <MoleculeEditorPage {...props} />}
          </Route>

          <Route path="/main" >
            <MainPage />
          </Route>

          <Route path="/help" >
            <HelpPage />
          </Route>

          <Route path="/sub-search/:page" >
            {(props: any) => <SubSearchPage {...props} />}
          </Route>

          <Route path="/exact-search/:molid" >
            {(props: any) => <ExactSearchPage {...props} />}
          </Route>

          <Route path="/exact-search/" >
            {(props: any) => <ExactSearchPage {...props} />}
          </Route>

          <Route path="/spectrum-search" >
            {(props: any) => <SpectrumSearchPage {...props} />}
          </Route>

          <Route path="/publication-search" >
            {(props: any) => <PublicationSearchPage {...props} />}
          </Route>

          <Route path="/spectrum-search-result/:page" >
            {(props: any) => <SpectrumSearchResultPage {...props} />}
          </Route>

          <Route path="/spectrum/:spectrid" >
            {(props: any) => <SpectrumPage {...props} />}
          </Route>

          <Route path="/spectrum-compare/:spectrid" >
            {(props: any) => <SpectrumComparePage {...props} />}
          </Route>

          <Route exact path="/"
            render={() => { return (<Redirect to="/main" />) }}
          />

          <Route exact path="/register"
            render={(props: any) => { return (<RegisterPage {...props} />) }}
          />

          <Route exact path="/login"
            render={(props: any) => { return (<LoginPage {...props} />) }}
          />

          <Route exact path="/confirmation"
            render={(props: any) => { return (<ConfirmationPage {...props} />) }}
          />

         <Route exact path="/restore-confirmation"
            render={(props: any) => { return (<RestoreConfirmationPage {...props} />) }}
          />

          <Route exact path="/restore"
            render={(props: any) => { return (<RestorePage {...props} />) }}
          />

         <Route exact path="/personal"
            render={(props: any) => { return (<PersonalInfoPage {...props} />) }}
          />

          <Route exact path="/search-history"
            render={(props: any) => { return (<SearchHistoryPage {...props} />) }}
          />

         <Route exact path="/search-operation"
            render={(props: any) => { return (<SearchOperationPage {...props} />) }}
          />

         <Route exact path="/privacy-policy"
            render={(props: any) => { return (<PrivacyPolicyPage {...props} />) }}
          />

        <Route exact path="/premium"
            render={(props: any) => { return (<PremiumPage {...props} />) }}
          />

        </Switch>
      </ThemeProvider>

    </Router>
  );
}

export default App;
