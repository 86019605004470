import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2'
import JoinFullIcon from '@mui/icons-material/JoinFull';
import JoinInnerIcon from '@mui/icons-material/JoinInner';


import { Item, Item2 } from '../../styles';
import Header from '../../components/HeaderComponent';
import ProgressControl from '../../components/ProgressControl';
import ICombineQuery from '../../schemas/History/ICombineQuery';
import { fetchGet, fetchPost } from '../../services/GettingData';
import { IOperationType } from '../../schemas/History/IOperationType';
import ICombineResponse from '../../schemas/History/ICombineResponse';
import IQueryHistoryModel from '../../schemas/History/IQueryHistoryModel';


type HistoryOperationProp = {
  location: {
    state: {
      operationType: IOperationType,
      leftOperand: IQueryHistoryModel,
      rightOperand: IQueryHistoryModel
    }
  }
};


export const SearchOperationPage: React.FC<HistoryOperationProp> = (props) => {

  const [combineResponse, setCombineResponse] = useState<ICombineResponse>();
  const [combineQuery, setCombineQuery] = useState<ICombineQuery>();
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const combine = async () => {
    const response = await fetchPost('user/history/queries/combine', JSON.stringify(combineQuery), false, true);
    if (response.ok)
      setCombineResponse(await response.json() as ICombineResponse);
    else {
      setIsLoading(false);
      throw ('no data');
    }
    setIsLoading(false);
  }


  useEffect(() => {

    setCombineQuery({ 
      queries_ids: [props.location.state.leftOperand.query_id, props.location.state.rightOperand.query_id],
      by:'',
      method:''
    });
  }, []);
 

  return (
    <Grid container spacing={0} className='main-frame'>
      <Grid xs={12}>
        <Item2><div style={{ height: '2em' }}></div></Item2>
      </Grid>
      <Header title='Search Union' showLogin={true} />
      {isLoading ? <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <ProgressControl /></Grid> :
        <>
          <Grid container xs={12}>
            <Grid md={5} style={{ marginTop: '4em', padding: '1em' }}></Grid>
            <Grid md={2} style={{ marginTop: '4em', padding: '1em' }}>

              {props.location.state.operationType === IOperationType.IntersectionByCompounds &&
                <Item className='history-item'>
                  <div>
                    <div>{IOperationType.IntersectionByCompounds}</div>
                    <div><JoinInnerIcon style={{ verticalAlign: 'middle', fontSize: '4em' }} fontSize="large" color="primary" /></div>
                  </div>
                </Item>}


              {props.location.state.operationType === IOperationType.IntersectionByPublications &&
                <Item className='history-item'>
                  <div>
                    <div>{IOperationType.IntersectionByPublications}</div>
                    <div><JoinInnerIcon style={{ verticalAlign: 'middle', fontSize: '4em' }} fontSize="large" color="primary" /></div>
                  </div>
                </Item>
              }


              {
                props.location.state.operationType === IOperationType.UnionByCompounds ||
                props.location.state.operationType === IOperationType.UnionByPublications &&
                <Item className='history-item'>
                  <div>
                    <div>{props.location.state.operationType}</div>
                    <div><JoinFullIcon style={{ verticalAlign: 'middle', fontSize: '4em' }} fontSize="large" color="primary" /></div>
                  </div>
                </Item>}

            </Grid>
            <Grid md={5} style={{ marginTop: '4em', padding: '1em' }}>
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid md={1}></Grid> 
            <Grid md={5} style={{ marginTop: '4em', padding: '1em' }}>
            Full reference 


            </Grid>
            <Grid md={5} style={{ marginTop: '4em', padding: '1em' }}>
            <div>Compounds found: 155</div>
            <div>H NMR spectra: 100</div>
            <div>C NMR spectra: 50</div>
            <div>F NMR spectra: 31</div>
            </Grid>
            <Grid md={1}></Grid> 
                        
          </Grid>
        </>

      }
    </Grid >
  );
}
export default SearchOperationPage;
