import { fetchPost } from '../services/GettingData';

import IResolvedMolecule from '../schemas/IResolvedMolecule';

async function nameToMolecule(name: string): Promise<IResolvedMolecule> {
    const response = await fetchPost('/misc/name-to-molfile?name=' + name, '');
    const molecule = await response.json();
    return molecule;
}

export { nameToMolecule };