import IPublicationModel from '../schemas/Publication/IPublicationModel';

const dictJNames: Record<string, string> = {
  'Angewandte Chemie International Edition': 'Angew. Chemie Int. Ed.',
  'J. Org. Chem.': 'J. Org. Chem.',
  'Chemistry – A European Journal': 'Chem. – A Eur. J.',
  'Journal of the American Chemical Society': 'J. Am. Chem. Soc.',
  'Molecules': 'Molecules',
  'Journal of Medicinal Chemistry': 'J. Med. Chem.',
  'Chemical Communications': 'Chem. Comm.',
  'Beilstein Journal of Organic Chemistry': 'Belstein J. Org. Chem.',
  'Chemistry - A European Journal': 'Chem. – A Eur. J.',
  'Synlett': 'Synlett',
  'European Journal of Organic Chemistry': 'Eur. J. Org. Chem.',
  'Org. Lett.': 'Org. Lett.',
  'Synthesis': 'Synthesis',
  'Synthesis: an Anglophone Journal of Comparative Literary Studies': 'Synthesis',
};


function transformJsonToGost(startData: IPublicationModel): string {
  let jName = startData.journal in dictJNames ? dictJNames[startData.journal] : startData.journal
  if (jName.slice(-1) !== '.')
    jName += '.';

  const year = startData.year;
  const pages = startData.pages;
  const volume = startData.volume;
  const title = startData.title;

  let namesSplited = startData.authors.split(';')
  let result = '';

  if (namesSplited.length > 4) {
    let names = namesSplited.slice(0, 3).map(x => {
      return x.split(',')[1].replace(/[a-z]+/g, '.') + ' ' + x.split(',')[0];
    }).join(', ') + ' et al.';

    result = title + ' / ' + names + ' // ' + jName + ' — ' + year + '. — Vol. ' + volume + '. — P. ' + pages + '.';
  } else if (namesSplited.length === 4) {
    let names = namesSplited.map(x => {
      return x.split(',')[1].replace(/[a-z]+/g, '.') + ' ' + x.split(',')[0];
    }).join(', ');

    result = title + ' / ' + names + ' // ' + jName + ' — ' + year + '. — Vol. ' + volume + '. — P. ' + pages + '.';
  } else if (namesSplited.length < 4) {
    let firstAuthor = namesSplited[0].split(',')[0] + ' ' + namesSplited[0].split(',')[1].replace(/[a-z]+/g, '.');
    let names = namesSplited.map(x => {
      return x.split(',')[1].replace(/[a-z]+/g, '.') + ' ' + x.split(',')[0];
    }).join(', ');

    result = firstAuthor + ' ' + title + ' / ' + names + ' // ' + jName + ' — ' + year + '. — Vol. ' + volume + '. — P. ' + pages + '.';
  }
  return result;
}


function transformJsonToElsevier(startData: IPublicationModel): string {
  const names = startData["authors"].split(';').map((x: string) => {
    return x.split(',')[1].replace(/[a-z]+/g, '.') + ' ' + x.split(',')[0];
  }).join(', ');

  let jName = dictJNames[startData["journal"]] || startData["journal"];
  if (jName.slice(-1) !== '.') {
    jName += '.';
  }

  const year = startData["year"];
  const pages = startData["pages"];
  const volume = startData["volume"];
  const title = startData["title"];

  const result = `${names}, ${title}, ${jName} ${volume} (${year}) ${pages}.`;

  return result;
}


function transformJsonToWiley(startData: IPublicationModel): string {
  const names = startData["authors"].split(';').map((x: string) => {
    return x.split(',')[1].replace(/[a-z]+/g, '.') + ' ' + x.split(',')[0];
  }).join(', ');

  let jName = dictJNames[startData["journal"]] || startData["journal"];
  const year = startData["year"];
  const pages = startData["pages"];
  const volume = startData["volume"];
  const result = `${names}, <i>${jName}</i> <b>${year}</b>, <i>${volume}</i>, ${pages}.`;

  return result;
}


function transformJsonToRsc(startData: IPublicationModel): string {
  const names = startData["authors"].split(';').map((x: string) => {
    return x.split(',')[1].replace(/[a-z]+/g, '.') + ' ' + x.split(',')[0];
  }).join(', ');

  let jName = dictJNames[startData["journal"]] || startData["journal"];
  const year = startData["year"];
  const pages = startData["pages"];
  const volume = startData["volume"];
  const result = `${names}, <i>${jName}</i>, ${year}, <b>${volume}</b>, ${pages}.`;

  return result;
}


function transformJsonToAcs(startData: IPublicationModel): string {
  const names = startData.authors
    .split(';')
    .map((author) => {
      const [lastName, firstName] = author.split(',');
      return `${lastName.split(' ')[0]}, ${firstName.replace(/[a-z]+/g, '.').trim()}`;
    })
    .join('; ');

  const jName = dictJNames[startData.journal] || startData.journal;
  const year = startData.year;
  const pages = startData.pages;
  const volume = startData.volume;
  const title = startData.title;
  const result = `${names} ${title} <i>${jName}</i>, <b>${year}</b>, <i>${volume}</i>, ${pages}`;
  return result;
}


function downloadRisFile(publication?: IPublicationModel) {
  if (!publication) return;
  const element = document.createElement("a");
  const blob = new Blob([getRisFile(publication!)], { type: "text/plain" });
  element.href = URL.createObjectURL(blob);
  element.download = "file.ris";
  //element.en 'w', encoding = 'utf-8
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}


function getRisFile(publication: IPublicationModel): string {
  const lines: string[] = [];
  lines.push('TY  - JOUR');
  lines.push('T1  - ' + publication.title);

  const names = publication.authors.split(';');
  for (const name of names) {
    lines.push('A1  - ' + name);
  }

  lines.push('Y1  - ' + publication.year);
  lines.push('SP  - ' + publication.pages.split('-')[0]);
  lines.push('EP  - ' + publication.pages.split('-')[1]);
  lines.push('VL  - ' + publication.volume);
  lines.push('JF  - ' + publication.journal);
  lines.push('DO  - ' + publication.urn);
  lines.push('M3  - ' + publication.urn);
  lines.push('UR  - ' + publication.url);

  return lines.join('\n');
}


async function copyPublication(event: any, svgMoleculeString, spectrumString, publicationText, publicationUrn) {
  try {
    if (!svgMoleculeString || !spectrumString)
      throw new Error("SVG content or spectrum string is empty.");

    const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svgElement.innerHTML = svgMoleculeString;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = 'data:image/svg+xml;base64,' + btoa(svgMoleculeString);

    const widthMatch = svgMoleculeString.match(/width\s*=\s*\"?(\d+)\"?/);
    const width = widthMatch ? Number(widthMatch[1]) : null;
    const heightMatch = svgMoleculeString.match(/height\s*=\s*\"?(\d+)\"?/);
    const height = heightMatch ? Number(heightMatch[1]) : null;
    if (height) canvas.height = height;
    if (width) canvas.width = width;

    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      const pngDataUrl = canvas.toDataURL('image/png');
      //const pngDataUrl = canvas.toDataURL("image/jpeg", 1.0);
      //const pngDataUrl = canvas.toDataURL('image/webp');
      const referenceString = publicationText;
      const publicationUrnString = 'https://doi.org/' + publicationUrn;

      const htmlToClipboard = `<div>
           <div>
             <picture><img class="dpd" height="200" width="250" src="${pngDataUrl}" id="hplogo" data-atf="1" data-frt="0"></picture>
           </div>
           <div>
             <span style="font-weight: 600"> This spectrum corresponds to literature data:</span>
           </div>
           <div>
               <span style="font-weight: 600">Spectrum string:</span>
               <span>${spectrumString}</span>
           </div>
           <div>
             <span style="font-weight: 600">Reference:</span>
               <a href=${publicationUrnString} target="_blank">${referenceString}</a>
           </div>
         </div>`;
      navigator.clipboard.write([
        new ClipboardItem({
          "text/html": new Blob([htmlToClipboard], {
            type: "text/html",
          }),
        }),
      ]).then(() => {
        event.preventDefault();
        //this.setState({ isCopied: true });
      }).catch((error) => {
        console.error(error);
        alert('Error copying to clipboard: ' + error.message);
      });
    };
  } catch (error) {
    console.error(error);
    alert('Error copying to clipboard: ' + error.message);
  }
};


export { getRisFile, downloadRisFile, transformJsonToAcs, transformJsonToGost, transformJsonToElsevier, transformJsonToWiley, transformJsonToRsc, copyPublication };
