import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

type IDialogProps = {
  isDialogOpened: boolean,
  onClose: (confirmed: boolean,  mistakeText: string) => void
};

export default function ReportConfirmationDialog(props: IDialogProps) {
  const theme = useTheme();
  const [mistakeText, setMistakeText] = React.useState<string>('');
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  const handleCloseYes = () => props.onClose(true, mistakeText);
  const handleCloseNo = () => props.onClose(false, '');
  

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.isDialogOpened}
        onClose={handleCloseNo}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              error
              fullWidth
              id="filled-error-helper-text"
              defaultValue=""
              multiline
              onChange={(e) => { setMistakeText(e.target.value); }}
              helperText="Please, provide details of mistake"
              variant="filled"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseNo}>
            Cancel
          </Button>
          <Button onClick={handleCloseYes} autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}