import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';

import { Item2 } from '../../styles';
import ISession from '../../schemas/Login/ISession';
import Header from '../../components/HeaderComponent';
import ErrorDialog from '../../components/ErrorDialog';
import { fetchGet, fetchPost } from '../../services/GettingData';
import ProgressControl from '../../components/ProgressControl';


export const RegisterPage: React.FC = (props) => {

  const [email, setEmail] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [position, setPosition] = useState<string>('');
  const [agree, setAgree] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [institution, setInstitution] = useState<string>('');
  const [passwordOne, setPasswordOne] = useState<string>('');
  const [passwordTwo, setPasswordTwo] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSubmissionOpen, setSubmissionOpen] = useState<boolean>(false);
  const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
  const [session, setSession] = useState<ISession | undefined>(undefined);
  const [sessionActive, makeSessionActive] = useState<boolean>(false);
  
  const history = useHistory();

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleAgree = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgree(event.target.checked);
  }


  const submitRegistration = () => {
    const fetchData = async () => {
      setLoading(true);
      const url = '/user/register';
  
      const response = await fetchPost(url,
        JSON.stringify({
          'password': passwordOne,
          'firstname': firstName,
          'lastname': lastName,
          'email': email,
          'institution': institution,
          'position': position,
        }), false);
      if (response.ok) {
        setLoading(false);
        goBack();
      }
      else {
        setLoading(false);
        const json = await response.json();
        if (json.detail)
         showError(json.detail);
        else
         showError('Registration failed');
      }
    }
    fetchData()
      .catch(console.error);
  }

  
  const showError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setErrorDialogOpened(true);
  }


  const onCloseErrorDialog = () => {
    setErrorDialogOpened(false);
  }


  const enableControls = () => {
    setAgree(true);
    makeSessionActive(true);
  }


  React.useEffect(() => {
    setSubmissionOpen(!!email && !!passwordOne 
                              && !!passwordTwo 
                              && passwordOne === passwordTwo 
                              && email.indexOf('@') > -1
                              && !!position
                              && !!institution
                              && agree === true
                              && !!lastName 
                              && !!firstName);
  }, [email, passwordOne, passwordTwo, lastName, firstName, agree, position, institution]);


  const goBack = () => {
    if (!session)
      history.push({ pathname: '/confirmation', state: {email: email}});
  }


  return (
    <Grid container className='main-frame' spacing={0}>
      <Grid container md={12}>
        <Item2><div style={{ height: '2em' }}></div></Item2>
      </Grid>

      <ErrorDialog isDialogOpened={isErrorDialogOpened}
                errorMessage={errorMessage}
                warningMessage={''}
                onClose={onCloseErrorDialog} />

      <Header title='Registration' showLogin={false} />
      <Grid style={{ height: '2em' }} xs={12}></Grid>
      {isLoading ? <ProgressControl /> :
      <>
      <Grid xs={12} style={{ marginTop: '2em' }}>
        <TextField type="text"
          value={firstName}
          sx={{
            input: {
              "&::placeholder": {
                opacity: .5,
              },
            },
            label: { color: 'blue' }
          }}
          onChange={(c) => {
            setFirstName(c.target.value)
          }}
          helperText=""
          style={{ width: '100%', marginTop: '1em' }}
          placeholder='First Name' />
      </Grid>
      <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <TextField type="text"
          value={lastName}
          sx={{
            input: {
              "&::placeholder": {
                opacity: .5,
              },
            },
            label: { color: 'blue' }
          }}
          onChange={(c) => {
            setLastName(c.target.value)
          }}
          helperText=""
          style={{ width: '100%', marginTop: '1em' }}
          placeholder='Last Name' />

      </Grid>
      <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <TextField type="text"
          value={email}
          disabled={!!session}
          sx={{
            input: {
              "&::placeholder": {
                opacity: .5,
              },
            },
            label: { color: 'blue' }
          }}
          onChange={(c) => {
            setEmail(c.target.value)
          }}
          helperText=""
          style={{ width: '100%', marginTop: '1em' }}
          placeholder='email' />
      </Grid>

      <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <TextField className='reg-line'
          type="text"
          value={institution}
          sx={{
            input: {
              "&::placeholder": {
                opacity: .5,
              },
            },
            label: { color: 'blue' }
          }}
          onChange={(c) => {
            setInstitution(c.target.value)
          }}
          helperText=""
          style={{ width: '100%', marginTop: '1em' }}
          placeholder='Institution' />
      </Grid>

      <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <TextField className='reg-line'
          type="text"
          value={position}
          sx={{
            input: {
              "&::placeholder": {
                opacity: .5,
              },
            },
            label: { color: 'blue' }
          }}
          onChange={(c) => {
            setPosition(c.target.value)
          }}
          style={{ width: '100%', marginTop: '1em' }}
          placeholder='Position' />
      </Grid>

      <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <TextField type="password"
          value={passwordOne}
          sx={{
            input: {
              "&::placeholder": {
                opacity: .5,
              },
            },
            label: { color: 'blue' }
          }}
          onChange={(c) => {
            setPasswordOne(c.target.value)
          }}
          style={{ width: '100%', marginTop: '1em' }}
          placeholder='password' />
      </Grid>

      <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <TextField className='reg-line'
          type="password"
          value={passwordTwo}
          sx={{
            input: {
              "&::placeholder": {
                opacity: .5,
              },
            },
            label: { color: 'blue' }
          }}
          onChange={(c) => {
            setPasswordTwo(c.target.value)
          }}
          style={{ width: '100%', marginTop: '1em' }}
          placeholder='repeat password' />
      </Grid>

      <Grid container xs={12} style={{ marginTop: '1em' }}>
        <Grid xs={6} style={{ padding: '1em' }}>I agree with handling of my personal data by OdanChem according to our <a href="/privacy-policy" target="_blank">policy</a>.</Grid>
        <Grid xs={6} style={{ padding: '0.5em' }}>
          <Checkbox
            onChange={handleAgree}
            checked={agree}
            {...label}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          />
        </Grid>
      </Grid>

      <Grid container xs={12} style={{ marginTop: '3em'}}>
        <Grid xs={1}></Grid>
        <Grid xs={3} style={{ display: 'flex', justifyContent: 'center' }}></Grid>
        <Grid xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button disabled={!isSubmissionOpen} onClick={() => submitRegistration()} variant="contained">Submit</Button>
        </Grid>
        <Grid xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
        </Grid>
        <Grid xs={1}></Grid>
      </Grid>
      </>}

    </Grid>
  );
}

export default RegisterPage

