import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';

import { Item2 } from '../../styles';
import Header from '../../components/HeaderComponent';
import { fetchPost } from '../../services/GettingData';

type IConfirmationProps = {
    location: ConfirmationPagePS,
    backTo: string,
  };
  
  type ConfirmationPagePS = {
    state: {
        email?: string,
    }  
}

export const ConfirmationPage: React.FC<IConfirmationProps> = (props) => {

    const [email, setEmail] = useState<string>(props.location?.state?.email ? props.location?.state?.email : '');
    const [code, setCode] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isSubmissionOpen, setSubmissionOpen] = useState<boolean>(false);

    const history = useHistory();

    const keepTheToken = () => {
    }


    React.useEffect(() => {
        setSubmissionOpen(!!email && !!code && email.indexOf('@') > -1);
    }, [email, code]);


    const accountLogin = () => {
        const fetchData = async () => {
            const url = '/user/register/confirm';
            const response = await fetchPost(url,
                JSON.stringify({
                    'code': code,
                    'email': email,
                }), false);
            if (response.ok) {
                keepTheToken();
                setLoading(false);
                goBack();
            }
        }
        fetchData()
            .catch(console.error);
    }


    const goBack = () => {
        history.push('/');
    }


    return (
        <Grid container className='main-frame' spacing={0}>
            <Grid container md={12}>
                <Item2><div style={{ height: '2em' }}></div></Item2>
            </Grid>

            <Header title='Registration Confirmation' />
            <Grid style={{ height: '2em' }} xs={12}></Grid>
            <Grid xs={12} container style={{marginTop:'3em'}}>
                <Grid xs={3}></Grid>
                <Grid xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    <TextField
                        type="email"
                        value={email}
                        sx={{
                            input: {
                                "&::placeholder": {
                                    opacity: .5,
                                },
                            },
                            label: { color: 'blue' }
                        }}
                        onChange={(c) => {
                            setEmail(c.target.value)
                        }}
                        helperText=""
                        style={{ width: '100%', marginTop: '1em' }}
                        placeholder='email' />
                </Grid>
                <Grid xs={3}></Grid>
            </Grid>

            <Grid xs={12} container>
                <Grid xs={3}></Grid>
                <Grid xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    <TextField
                        type="text"
                        value={code}
                        sx={{
                            input: {
                                "&::placeholder": {
                                    opacity: .5,
                                },
                            },
                            label: { color: 'blue' }
                        }}
                        onChange={(c) => {
                            setCode(c.target.value)
                        }}
                        helperText=""
                        style={{ width: '100%', marginTop: '1em' }}
                        placeholder='confirmation code' />
                </Grid>
                <Grid xs={3}></Grid>
            </Grid>

            <Grid container xs={12} style={{ marginTop: '2em', display: 'flex', justifyContent: 'center' }}>
                <Grid>
                    <Button disabled={!isSubmissionOpen} onClick={() => accountLogin()} variant="contained">Submit</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ConfirmationPage;
