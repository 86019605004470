import React from 'react';
import { Link, useHistory } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Pagination from '@mui/material/Pagination';

import AppSettings from '../../API/appSettings';
import translate from '../../services/Translation';
import Header from '../../components/HeaderComponent';
import { fetchPost } from '../../services/GettingData';
import ErrorDialog from '../../components/ErrorDialog';
import MoleculeView from '../../components/MoleculeView';
import ProgressControl from '../../components/ProgressControl';
import FilterComponent from '../../components/FilterComponent';
import HelpIconWithHint from '../../components/HelpIconWithHint'
import ISubSearchFilterModel from '../../schemas/ISubSearchFilterModel';
import { ICompoundSearchResponse } from '../../schemas/ICompoundSearchResponse';
import '../../App.css';
import { Item, Item2 } from '../../styles';

export type SubSearchProps = { svgContent: string, moleculeString: string };


export const SubSearchPage: React.FC<SubSearchProps> = (props) => {

  const getSVG = (): string => {
    return localStorage.getItem(AppSettings.LOCAL_STORAGE_SVG_KEY + AppSettings.MAIN) || '';
  }

  const getMolecule = (): string => {
    return localStorage.getItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.MAIN) || '';
  }

  const getExtraSVGs = (): string[] => {
    let svg = localStorage.getItem(AppSettings.LOCAL_STORAGE_SVG_KEY + AppSettings.EXTRA) || '';
    localStorage.removeItem(AppSettings.LOCAL_STORAGE_SVG_KEY + AppSettings.EXTRA);
    return svg ? [svg] : []
  }

  const getExtraMolecules = (): string[] => {
    let mol = localStorage.getItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.EXTRA) || '';
    localStorage.removeItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.EXTRA);
    return mol ? [mol] : []
  }

  const loadFilterSettings = (): ISubSearchFilterModel => {
    let filters = {
      mol_query: getMolecule(),
      compound_props: JSON.parse(localStorage.getItem('compound_props') || '[]'),
      show_radicals: false,
      iterate_tautomers: false,
      optional_mols: getExtraMolecules(),
      nmr_types: JSON.parse(localStorage.getItem('nmr_types') || '[]'),
      optional_svgs: getExtraSVGs(),//A13
    }
    localStorage.removeItem('nmr_types');
    localStorage.removeItem('compound_props');
    return filters;
  }

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [svgContent] = React.useState<string>(getSVG());

  const [compoundSearchResponse, setCompoundSearchResponse] = React.useState<ICompoundSearchResponse>({
    items: [],
    total: 0,
    page: 1,
    size: 10
  });
  const [isErrorDialogOpened, setIsErrorDialogOpened] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [subSearchFilterModel, setSubSearchFilterModel] = React.useState<ISubSearchFilterModel>
    ((props as any).location?.state?.subSearchFilterModel ? (props as any).location?.state?.subSearchFilterModel : loadFilterSettings());
  const [currentPage, setCurrentPage] = React.useState<number>((props as any).match.params['page']);

  let history = useHistory();


  const searchAsSub = async (page: number): Promise<ICompoundSearchResponse> => {
    const endPoint = '/compound/search/sub?page=' + page + '&size=' + compoundSearchResponse?.size;
    const response = await fetchPost(endPoint, JSON.stringify(subSearchFilterModel));
    if (!response.ok) {
      showError(response.statusText);
      return { items: [], total: 0, page: 0, size: 0 }
    }
    return await response.json();
  }

  const refreshSub = async (page: number = 1) => {
    setCompoundSearchResponse(await searchAsSub(page));
    setIsLoading(false);
  }


  const saveFilterSettings = () => {
    localStorage.setItem('compound_props', JSON.stringify(subSearchFilterModel?.compound_props));
    localStorage.setItem('nmr_types', JSON.stringify(subSearchFilterModel?.nmr_types));
    localStorage.setItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.EXTRA, subSearchFilterModel?.optional_mols[0] || '');
  }


  React.useEffect(() => {
    refreshSub(currentPage);
  }, [currentPage]);


  const pageChangeHandle = async (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    history.push({
      pathname: '/sub-search/' + page,
      state: { subSearchFilterModel: subSearchFilterModel, page: page }
    });
  }


  const showError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setIsLoading(true);
  }


  const closeErrorDialog = () => {
    setIsErrorDialogOpened(false);
  }


  const newSearch = (): void => {
    localStorage.removeItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.MAIN);
    history.push('/editor/main');
  }


  const applyFilter = async (): Promise<void> => {
    setIsLoading(true);
    await refreshSub();
  }


  const filterChanged = (filterModel: ISubSearchFilterModel) => {
    setSubSearchFilterModel(prev =>
    ({
      ...filterModel,
      mol_query: (subSearchFilterModel?.mol_query as string),
      optional_mols: (subSearchFilterModel?.optional_mols as string[]),
      optional_svgs: (subSearchFilterModel?.optional_svgs as string[]),//A13
    }
    ))
  }


  return (<Grid container spacing={0} className='main-frame'>
    <Grid xs={12}>
      <Item2><div style={{ height: '2em' }}></div></Item2>
      { }
    </Grid>
    <ErrorDialog isDialogOpened={isErrorDialogOpened}
      errorMessage={errorMessage}
      onClose={closeErrorDialog} />

    <Header title='' helpAddress='help#wdaafpc' />

    <Grid container spacing={4} style={{ minWidth: "250px", marginTop: '4em' }} md={12}>
      <Grid>
      </Grid>
      <Grid style={{ marginLeft: '1em', marginRight: '1em', display: "flex", alignItems: "center" }}>
        <div></div>
        <Item2>
          <div style={{ marginBottom: '1em' }}>
            <Tooltip arrow title='start new search'>
              <Button onClick={() => newSearch()} variant="contained">New Search</Button>
            </Tooltip>
            <HelpIconWithHint title={''}
              text={'Drop results and draw new query'}
              handleOpen={() => { }} />
          </div>
          <Link to='/editor/main'>
            <Tooltip arrow title='modify query'>
              <Button variant="contained">Edit Search</Button>
            </Tooltip>
          </Link>
          <HelpIconWithHint title={''}
            text={'Drop results and modify the query'}
            handleOpen={() => { }} />
        </Item2>
      </Grid>
      <Grid style={{ minWidth: "250px", marginLeft: '1em', marginRight: '1em' }}>
        <div><span className='box-title'>Substruct search</span></div>
        <Item style={{ width: "250px", marginTop: '20px' }}>
          <MoleculeView link={''}
            svgContent={svgContent}
            isMoleculeInContainer={true}
            moleculeString={props.moleculeString} />
        </Item>
      </Grid>

      <Grid style={{ minWidth: "250px", marginLeft: '1em', marginRight: '1em', }}>
        <div>Extra filter</div>
        <Item style={{ width: "250px", marginTop: '12px' }} onClick={() => { saveFilterSettings() }} >
          {<MoleculeView link={'/editor/extra'}
            svgContent={subSearchFilterModel.optional_svgs[0] || ''}//A13
            //svgContent={''}
            tooltip={'Open ketcher to draw substructure to filter results'}
            isMoleculeInContainer={true} />}
        </Item>
      </Grid>

      <Grid>
        <div style={{ marginBottom: '20px' }}><span className='box-title'>Filter</span></div>
        {<FilterComponent onChanged={filterChanged} subSearchFilterModel={subSearchFilterModel} />}
        <div style={{ marginBottom: '1em', marginTop:'2em', textAlign:'center' }}>
          <Tooltip arrow title='Apply selected filters to your results'>
            <Button onClick={applyFilter} variant="contained">Apply Filter</Button>
          </Tooltip>
          <HelpIconWithHint title={''}
            text={'Apply selected filters to the current search results'}
            handleOpen={() => { }} />
        </div>
      </Grid>


      <Grid style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginBottom: '1em' }}>
        </div>
      </Grid>
    </Grid>

    <Grid md={12} xs={12}>
      {!isLoading &&
        <Grid md={12} xs={12}>
          <Item id='block-stat'>
            <div className='molecules-count'> {isLoading ? '' : <>Found {compoundSearchResponse?.total ?
              compoundSearchResponse.total : 0} molecules</>}</div>
          </Item>
        </Grid>
      }
    </Grid>

    {isLoading ?
      <Grid container md={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <ProgressControl />
      </Grid> :
      compoundSearchResponse?.items &&
      compoundSearchResponse.items.map((compound, index) => (
        <Grid container xs={12} style={{ paddingTop: '0.7em' }} key={index} className='compound-feed'>
          <Grid xs={3}>
            <MoleculeView link={''} moleculeId={compound.id}
              svgContent={compound.svg}
              isMoleculeInContainer={true}
              inline={true}
              moleculeString={compound.molecule}
            />
          </Grid>
          <Grid container xs={9}>
            <Grid xs={4}>
              {compound.total_publications ? <div>Publications found: {compound.total_publications}</div> : null}
            </Grid>
            <Grid xs={4}>
              {
                Object.entries(compound.properties).map(([key, value]) => {
                  return (value > 0 && <div key={key}>{translate(key)} found: {value}</div>)
                })
              }
            </Grid>
            <Grid xs={4}>
              {compound.associated_spectra && Object.entries(compound.associated_spectra).map((spectra, count) => (
                <div key={spectra[0]}>{translate(spectra[0])} spectra found: {spectra[1]}</div>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ))
    }

    <Grid md={12} className='pagination-line' style={{ display: "inline" }} >
      {!isLoading &&
        <Grid style={{ width: '100%' }}>
          {compoundSearchResponse && compoundSearchResponse?.total > 0 &&
            <Pagination count={Math.ceil(compoundSearchResponse?.total / compoundSearchResponse?.size)}
              page={compoundSearchResponse?.page} onChange={pageChangeHandle} />}
        </Grid>
      }
    </Grid>
  </Grid>);
}
export default SubSearchPage;
