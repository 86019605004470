import React, { useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import '../App.css';
import { Item2 } from '../styles';
import OurLogo from '../components/OurLogo'


const PremiumPage: React.FC = () => {

    return (
        <Grid container style={{ paddingLeft: '2em', paddingRight: '2em' }}>
            <Grid xs={12}>
                <Item2>
                    <div style={{ height: '2em' }}></div></Item2>
            </Grid>

            <Grid container md={12} spacing={0}>
                <Grid md={4}>
                    <OurLogo />
                </Grid>
                <Grid md={8}>
                </Grid>
            </Grid>


            <Grid container spacing={0} md={12}>
                <Grid md={1} style={{ marginTop: '2em' }}></Grid>

                <Grid container style={{ margin: '4em', marginTop: '2em' }} xs={12}>
                    <Grid style={{ marginTop: '2em' }} xs={1}></Grid>
                    <Grid style={{ marginTop: '2em' }} xs={10}>
                        <Item2 style={{ textAlign: 'left', fontSize: 'medium', marginTop: '2em'  }}>
                            <div style={{ fontWeight: '600' }}>Premium version of OdanChem is available!</div>
                        </Item2>
                        <Item2 style={{ textAlign: 'left', fontSize: 'medium', marginTop: '2em'}}>   
                            <div>
                                In addition to the free version of the website you will get:
                                <ul>
                                    <li>Larger database</li>
                                    <li>Search by IR spectra - our database includes more than 1 million of IR spectra</li>
                                    <li>Additional tools for the search results filtering and intersections. This makes analysis of the reaction mixtures highly efficient.</li>
                                </ul>
                            </div>
                        </Item2>
                        <Item2 style={{ textAlign: 'left', paddingTop: '1em', fontSize: 'medium' }}>
                            <div>All questions regarding access to the premium version please send to <span style={{ fontWeight: '600'}}>sales@odanchem.org</span>.</div>
                            <div>The current version of the web-site remains free of charge and will be available for everybody.</div>
                        </Item2>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default PremiumPage;


