import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import CompoundSpectraComponent from './CompoundSpectraComponent';
import TabPanel from './TabPanel';
import ISpectra from '../schemas/Spectrum/ISpectra';
import translate from '../services/Translation';
import { getSpectrumOrder } from '../services/Spectrum';

type ICompoundSpectrumProps = { compoundId?: string, 
                                spectra: {},
                                svgContent: string };

const CompoundSpectrumComponent: React.FC<ICompoundSpectrumProps> = (props) => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    const header = (<div className='container' style={{ height: '2em', alignItems: 'center' }}>
        <span>NMR Spectra</span></div>);


    const panel = (spectrType: string, index: number) => (<TabPanel key={index} value={value} index={index}>
        <CompoundSpectraComponent compoundId={props.compoundId} spectrType={spectrType} /></TabPanel>);
    const tab = (spectrType: string, count: number, position: number) => (<Tab key={position} 
                                                                            label={translate(spectrType) + '(' + count + ')'} {...a11yProps(position)} />);


    return (<div>{header}
        <Tabs value={value} style={{ padding: '0px' }} onChange={handleChange} aria-label="basic tabs">
            {Object.keys(props.spectra).map((element, i)=> {
                return {spectrum_type: element, count:props.spectra[element]}}).filter(s => s.count > 0)
               .sort((a: ISpectra, b: ISpectra)=> getSpectrumOrder()[a.spectrum_type] - getSpectrumOrder()[b.spectrum_type])
               .map((spectra: ISpectra, index) => { return (tab(spectra.spectrum_type, spectra.count, index))})} 
        </Tabs>
        {Object.keys(props.spectra).map((element, i)=> {return {spectrum_type: element, count:props.spectra[element]}}).filter(s => s.count > 0)
               .sort((a: ISpectra, b: ISpectra)=> getSpectrumOrder()[a.spectrum_type] - getSpectrumOrder()[b.spectrum_type])
               .map((spectra: ISpectra, index) => {
            return (panel(spectra.spectrum_type, index))
        })}
    </div>);
};
export default CompoundSpectrumComponent;


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
