import React from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { Item, Item2 } from '../styles';
import Grid from '@mui/material/Unstable_Grid2';
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
import Tooltip from '@mui/material/Tooltip';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { getSpectrumTypes } from '../services/Spectrum';
import { getPropertyTypes } from '../services/Property';
import ISubSearchFilterModel from '../schemas/ISubSearchFilterModel';
import HelpIconWithHint from '../components/HelpIconWithHint'
import { Item3 } from '../styles';


type IFilterProps = {
    helpAddress?: string,
    onChanged: (filter: ISubSearchFilterModel) => void,
    subSearchFilterModel: ISubSearchFilterModel,
};


  
const FilterComponent: React.FC<IFilterProps> = (props) => {

    const nmrSpectrums = getSpectrumTypes();
    const compoundProperties = getPropertyTypes();

    let compoundPropertiesInversion: Record<string, string> = {}
    Object.keys(compoundProperties).forEach(k => {
        compoundPropertiesInversion[compoundProperties[k]] = k
    });

    const [spectrumType, setSpectrumType] = React.useState<string[]>(props.subSearchFilterModel.nmr_types);
    const [propertyType, setPropertyType] = React.useState<string[]>(props.subSearchFilterModel.compound_props.map(p => compoundPropertiesInversion[p]));


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: '250px',
            },
        },
    };


    const handleChangeShowRadicals = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChanged({ ...props.subSearchFilterModel, show_radicals: !event.target.checked });
    };


    const handleChangeSearchByTautomer = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChanged({ ...props.subSearchFilterModel, iterate_tautomers: event.target.checked });
    };


    const spectrumHandleChange = (event: SelectChangeEvent<typeof props.subSearchFilterModel.nmr_types>) => {
        const {
            target: { value },
        } = event;
        setSpectrumType(typeof value === 'string' ? value.split(',') : value);
    };


    React.useEffect(() => {
        props.onChanged({
            ...props.subSearchFilterModel,
            compound_props: propertyType.map(p => compoundProperties[p]),
            nmr_types: spectrumType.map(p => p)
        });
    }, [propertyType, spectrumType]);


    const propertyHandleChange = (event: SelectChangeEvent<typeof props.subSearchFilterModel.compound_props>) => {
        const {
            target: { value },
        } = event;
        setPropertyType(typeof value === 'string' ? value.split(',') : value);
    };


    return (
        <div style={{ width: '400px', height: '242px' }}>
            <Item style={{ height: '100%' }}>
                <Grid fontSize="medium">
                    Do not show radicals
                    <Checkbox title='Ignore molecules with radical electrons'
                        checked={!props.subSearchFilterModel.show_radicals}
                        onChange={handleChangeShowRadicals} inputProps={{ 'aria-label': 'controlled' }} />
                </Grid>

                <Grid fontSize="medium">
                    Search by tautomers
                    <Checkbox title=''
                        checked={props.subSearchFilterModel.iterate_tautomers}
                        onChange={handleChangeSearchByTautomer} inputProps={{ 'aria-label': 'controlled' }} />
                </Grid>


                <Grid container xs={12} spacing={0} style={{ justifyContent: 'left' }}>
                    <Grid xs={12} style={{ padding: '0 1.5em 0' }}>
                        <div style={{ marginTop: '1em' }}>
                            <Grid container>
                                <Grid style={{ marginRight: '0em' }} xs={11}>
                                    <Tooltip arrow
                                        title='Choose filters'>
                                        <FormControl fullWidth style={{ textAlign: 'left' }}>
                                            <InputLabel id="nmr-multiple-checkbox-label">Has NMR</InputLabel>
                                            <Select
                                                labelId="nmr-multiple-checkbox-label"
                                                id="nmr-multiple-checkbox"
                                                multiple
                                                value={spectrumType}
                                                onChange={spectrumHandleChange}
                                                input={<OutlinedInput label="Has NMR" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {Object.entries(nmrSpectrums).map((elem) => (
                                                    <MenuItem key={elem[0]} value={elem[1]}>
                                                        <Checkbox checked={props.subSearchFilterModel.nmr_types.indexOf(elem[1]) > -1} />
                                                        <ListItemText primary={elem[0]} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                                    <HelpIconWithHint title={''}
                                        text={'You can fix the presence of NMR spectra of particular type for your substructure search'}
                                        handleOpen={() => { }} />
                                </Grid>
                            </Grid>

                        </div>
                    </Grid>
                    <Grid xs={12} style={{ padding: '0 1.5em 0' }}>
                        <div style={{ marginTop: '1em', alignItems: 'left' }}>
                            <Grid xs={12} container>
                                <Grid xs={11} >
                                    <div>
                                        <Tooltip arrow
                                            title='Choose filters'>
                                            <FormControl fullWidth style={{ textAlign: 'left' }}>
                                                <InputLabel id="property-multiple-checkbox-label">Has Property</InputLabel>
                                                <Select
                                                    sx={{ p: 0 }}
                                                    labelId="property-multiple-checkbox-label"
                                                    id="property-multiple-checkbox"
                                                    style={{ alignContent: 'left'}}
                                                    value={propertyType}
                                                    multiple
                                                    onChange={propertyHandleChange}
                                                    input={<OutlinedInput label="Has Property" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    {Object.entries(compoundProperties).map((value) => (
                                                        <MenuItem key={value[1]} value={value[0]}>
                                                            <Checkbox checked={props.subSearchFilterModel.compound_props.indexOf(value[1]) > -1} />
                                                            <ListItemText primary={value[0]} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Tooltip>
                                    </div>

                                </Grid>

                                <Grid xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                                    <HelpIconWithHint title={''}
                                        text={'You can fix the presence of properties of particular type for your substructure search'}
                                        handleOpen={() => {}} />

                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Item>
        </div>);
};
export default FilterComponent;