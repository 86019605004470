import React, { lazy, Suspense } from 'react';
import ProgressControl from '../../components/ProgressControl';

const LazyMoleculeEditorPage = lazy(() => import('./LazyMoleculeEditorPage'));

function MoleculeEditorPage(props: any) {
  return (
    <div>
      <Suspense fallback={<div><ProgressControl/></div>}>
        <LazyMoleculeEditorPage session={props.match.params['session']} 
                                spectrumPreview={
                                  (props.location.state && 'spectrumPreview' in props.location.state) ? props.location.state['spectrumPreview'] : null
                                  } />
      </Suspense>
    </div>);
}

export default MoleculeEditorPage;
