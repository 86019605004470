import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2'
import Checkbox from '@mui/material/Checkbox';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import JoinInnerIcon from '@mui/icons-material/JoinInner';

import { Item, Item2 } from '../../styles';
import Header from '../../components/HeaderComponent';
import ErrorDialog from '../../components/ErrorDialog';
import MoleculeView from '../../components/MoleculeView';
import ProgressControl from '../../components/ProgressControl';
import NotAuthorizedError from "../../schemas/NotAuthorizedError";
import { fetchGet } from '../../services/GettingData';
import { IOperationType } from '../../schemas/History/IOperationType';
import IQueryHistoryModel from '../../schemas/History/IQueryHistoryModel';
import IQueryHistoryResponse from '../../schemas/History/IQueryHistoryResponse';


export const SearchHistoryPage: React.FC = (props) => {

  const [queryHistoryResponse, setQueryHistoryResponse] = useState<IQueryHistoryResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedHistoryItems, setSelectedHistoryItems] = useState<string[]>([]);
  const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');


  const historyItems = (historyItemId: string) => {
    if (selectedHistoryItems.includes(historyItemId)) {
      setSelectedHistoryItems(selectedHistoryItems.filter(item => item != historyItemId));
    }
    else
      setSelectedHistoryItems([...selectedHistoryItems, historyItemId]);
  }


  const showError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setErrorDialogOpened(true);
  }


  const toOperation = (operationType: IOperationType) => {
    if (selectedHistoryItems.length !== 2) {
      showError('2 items should be selected');
      return;
    }
    let leftOperand = queryHistoryResponse!.items!.filter(item => item.query_id === selectedHistoryItems[0])[0];
    let rightOperand = queryHistoryResponse!.items!.filter(item => item.query_id === selectedHistoryItems[1])[0];
   
    (props as any).history.push({
      pathname: '/search-operation',
      state: {
        operationType: operationType,
        leftOperand: leftOperand,
        rightOperand: rightOperand
      }
    });
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchGet('/user/history/queries/list?page=1&size=50', false, true);
        if (response.ok) {
          setQueryHistoryResponse(await response.json() as IQueryHistoryResponse);
        }
        else {
          setIsLoading(false);
          throw ('no data');
        }
        setIsLoading(false);
      }
      catch (e: any) {
        console.log('exception', e);
        setIsLoading(false);

        if (e instanceof NotAuthorizedError) {
          (props as any).history.push({ pathname: '/login', state: { backTo: '/search-history', welcomeMessage: false } })
        } else {
          //showError(e.toString());

          // this.setState(pre => ({
          //   ...pre,
          //   publicationSearchResponse: undefined,
          //   compoundsSearchResponse: undefined,
          //   isLoading: false,
          // }));
        }
      }
    }
    fetchData().catch(console.error);
  }, []);


  const findItem = (item: string): boolean => {
    return selectedHistoryItems.includes(item)
  }

  const closeErrorDialog = () => {
    setErrorDialogOpened(false);
  }


  const renderHistorySearchItem = (query_id: string, 
                                   moleculesTotal: number | undefined,
                                   publicationsTotal: number | undefined,
                                   spectraTotal: number | undefined,
                                   created_at: Date, title: string,
                                   svg: string | undefined = undefined) => {
    return (
      <Grid container xs={12} style={{ margin:'.5em'}}>
          <Grid xs={2}>
            <Checkbox style={{ transform: "scale(2)" }}
              onClick={() => historyItems(query_id)}
              checked={findItem(query_id)} />
          </Grid>
          <Grid xs={3} style={{ textAlign: 'left' }}>
              <Link to={{ pathname: '/spectrum-search', 
                state: { 'spectrumPreview': {
                  spectrumString:'llll'
              }}}}>
            

            <div style={{ fontSize: '1.5em', fontWeight: '600' }}>{title}</div>
            {moleculesTotal !== undefined && <div style={{ fontSize: '1.3em' }}>
              <span style={{ textAlign: 'left', marginRight: '.5em' }}>{moleculesTotal}</span>molecules
            </div>}
            {publicationsTotal !== undefined && 
            <div style={{ fontSize: '1.3em' }}>
              <span style={{ textAlign: 'left', marginRight: '.5em' }}>{publicationsTotal}</span>publications
            </div>}
            {spectraTotal !== undefined && 
            <div style={{ fontSize: '1.3em' }}>
              <span style={{ textAlign: 'left', marginRight: '.5em'}}>{spectraTotal}</span>spectra
            </div>}
            </Link>

          </Grid>
          <Grid xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
            {svg !== undefined &&
              <MoleculeView svgContent={svg} mini={true} inline={true} link={''} isMoleculeInContainer={true} />
            }
          </Grid>
          <Grid xs={3}>
            <div style={{ fontSize: '1.5em', fontWeight: '600' }}>
              {formatDate(created_at)}
            </div></Grid>
        </Grid>);
  }


  const formatDate = (date: Date) => {
    const reTime = /(\d+\-\d+\-\d+)\D(\d+\:\d+\:\d+)(\.\d+)/;
    var newTime = date.toLocaleString().replace(reTime, '$1');
    return newTime;
  }


  const renderItemHistorySearch = (queryHistoryModel: IQueryHistoryModel) => {
    if (queryHistoryModel.query_type === 'publication')
      return (renderHistorySearchItem(queryHistoryModel.query_id, 
                                      undefined,
                                      queryHistoryModel.result_preview.total,
                                      undefined,
                                      queryHistoryModel.created_at,
                                      'Publication search'));
    if (queryHistoryModel.query_type === 'spectrum')
      return (renderHistorySearchItem(queryHistoryModel.query_id, 
                                      queryHistoryModel.result_preview.spectra.total,
                                      queryHistoryModel.result_preview.molecules.total,
                                      queryHistoryModel.result_preview.publications.total,
                                      queryHistoryModel.created_at,
                                      queryHistoryModel.query.spectrum_type.replace('_', ' ') + ' spectrum search'));
    if (queryHistoryModel.query_type === 'compound_sub' || queryHistoryModel.query_type === 'compound_exact')
      return (renderHistorySearchItem(queryHistoryModel.query_id, 
                                      queryHistoryModel.result_preview.molecules.total,
                                      queryHistoryModel.result_preview.publications.total,
                                      undefined,
                                      queryHistoryModel.created_at,
                                      'Compound search',
                                      queryHistoryModel.result_preview.svg
                                      ));
    return (<>Unknown search</>);
  }

  return (
    <Grid container spacing={0} className='main-frame'>
      <Grid xs={12}>
        <Item2><div style={{ height: '2em' }}></div></Item2>
      </Grid>
      <ErrorDialog isDialogOpened={isErrorDialogOpened} 
                   errorMessage={errorMessage}
                   warningMessage={''}
                   onClose={closeErrorDialog}/>
      <Header title='History of Search' showLogin={true} />
      {isLoading ? <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <ProgressControl /></Grid> :
        <Grid container xs={12}>
          <Grid md={2} style={{ marginTop: '4em', padding: '1em' }}>
            <Item className='history-item'>
              <div style={{cursor: 'pointer'}} onClick={() => toOperation(IOperationType.IntersectionByCompounds)}>
                <div>Intersection by compounds</div>
                <div><JoinInnerIcon style={{ verticalAlign: 'middle', fontSize: '4em' }} fontSize="large" color="primary" /></div>
              </div>
            </Item>

            <Item className='history-item'>
              <div style={{cursor: 'pointer'}} onClick={() => toOperation(IOperationType.IntersectionByPublications)}>
                <div>Intersection by publications</div>
                <div><JoinInnerIcon style={{ verticalAlign: 'middle', fontSize: '4em' }} fontSize="large" color="primary" /></div>
              </div>
            </Item>

            <Item className='history-item'>
              <div style={{cursor: 'pointer'}} onClick={() => toOperation(IOperationType.UnionByCompounds)}>
                <div>Union by compounds</div>
                <div><JoinFullIcon style={{ verticalAlign: 'middle', fontSize: '4em' }} fontSize="large" color="primary" /></div>
              </div>
            </Item>
            
            <Item className='history-item'>
              <div style={{cursor: 'pointer'}} onClick={() => toOperation(IOperationType.UnionByPublications)}>
                <div>Union by publication</div>
                <div><JoinFullIcon style={{ verticalAlign: 'middle', fontSize: '4em' }} fontSize="large" color="primary" /></div>
              </div>
            </Item>
          </Grid>

          <Grid md={8} style={{ marginTop: '4em', padding: '1em' }}>
            {queryHistoryResponse?.items.map((elem, index) => (
              <Item key={index} style={{ marginBottom: '1em' }}>
                {
                  renderItemHistorySearch(elem)
                }
              </Item>
            ))}
          </Grid>
        </Grid>
      }
    </Grid >
  );
}
export default SearchHistoryPage;
