import React from 'react';
import Box from '@mui/material/Box';
import {Tooltip} from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
import Typography from '@mui/material/Typography';


type IButtonWithHelp = {
    title: string,
    disabled: boolean,
    onClick?: () => void,
    variant: string,
    text: string,
    tooltip: string,
  };
  
  export const ButtonWithHelp: React.FC<IButtonWithHelp> = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        borderRadius: '6px',
        bgcolor: 'background.paper',
        border: '1px',
        padding: '2em',
      };
  
    return (<>
    <Tooltip arrow title={props.tooltip}> 
      <Button onClick={props.onClick} disabled={props.disabled} variant={ props.variant === 'contained' ? "contained" : "outlined" } >{props.title}</Button>
    </Tooltip>

    <HelpOutlineSharpIcon onClick={handleOpen} style={{verticalAlign:'middle'}} fontSize="large" color="primary"/>
    <Modal  
          keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          > 
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            {''}
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            {props.text}
          </Typography>
        </Box>
    </Modal>
    </>);
  }
  export default ButtonWithHelp;
  