import React, { useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import '../App.css';
import { Item2 } from '../styles';
import OurLogo from '../components/OurLogo'


const HelpPage: React.FC = () => {
    useEffect(() => {
        let re = /#(.+)/g;
        let found = re.exec(window.location.href)
        if (found) {
            let hash = found[1];
            const el = hash && document.getElementById(hash)
            if (el) {
                el.scrollIntoView({ behavior: "smooth" })
            }
        }
    }, [window.location.href])

    
return (
        <Grid container style={{ paddingLeft: '2em', paddingRight: '2em' }}>
            <Grid xs={12}>
                <Item2>
                    <div style={{ height: '2em' }}></div></Item2>
            </Grid>

            <Grid container md={12} spacing={0}>
                <Grid md={4}>
                    <OurLogo />
                </Grid>
                <Grid md={8}>
                </Grid>
            </Grid>


            <Grid container spacing={0} md={12}>
                <Grid md={1} style={{ marginTop: '2em' }}></Grid>

                <Grid container style={{ margin: '4em', marginTop: '2em' }} xs={12}>
                    <Grid style={{ marginTop: '2em' }} xs={1}></Grid>
                    <Grid style={{ marginTop: '2em' }} xs={10}>
                        <Item2 style={{ textAlign: 'left', fontSize: 'medium', }}>
                            <p id='wcod'></p>
                                <div style={{ fontWeight: '600', fontStyle: 'Italic' }}>What can OdanChem do?</div>
                                <div>
                                    There are three types of chemical information search:
                                    <ul style={{ counterReset: 'item' }}>
                                        <li>Spectrum search - upload your NMR spectrum and find the compounds presenting in the reaction mixture (exceptional feature of OdanChem).</li>
                                        <li>Compound search - draw your compound and find relevant data about it</li>
                                        <li>Publication search - insert doi and find all compounds and spectra described in this publication.</li>
                                    </ul>
                                </div>
                        </Item2>

                        <Item2 style={{ textAlign: 'left', paddingTop: '1em', fontSize: 'medium' }}>
                            <p id='wics'> <span style={{ fontWeight: '600', fontStyle: 'Italic' }}>What is compound search?</span></p>
                                <div>Creation of a request: you can draw molecule in ketcher, insert its IUPAC name or CAS number or SMILES string. We do not have a full library of CAS numbers so the most reliable variant is using IUPAC name or manual drawing of the structure.</div>
                                <div style={{ textAlign: 'left', paddingTop: '.5em' }}>IMPORTANT: at this moment we do not have the generic search. So, it is not possible to add “any atom”, or generic group (like G, G*, ACY and so on) and search by it. This feature will be added later.</div>
                                <img style={{ paddingTop: '2em', width: '400px' }} src='/manual/search_option.gif' className='center-img' />
                        </Item2>


                        <Item2 style={{ textAlign: 'left', paddingTop: '1em', fontSize: 'medium' }}>
                            <p id='htuk'>
                                <span style={{ fontStyle: 'Italic', fontWeight: '600' }}>How to use Ketcher?</span></p>
                                <div>Ketcher is a standard molecule-drawing tool with classical toolbars. Some additional information could be found
                                    <a href='https://lifescience.opensource.epam.com/ketcher/index.html'> here</a>. However, there are some features to be noted:</div>
                                <ul>
                                    <li>You can upload information about your molecule in a relevant digital format like SMILES, InChi, MolFile, etc. Just copy your SMILES to the clipboard and press ctrl+V to the Ketcher desktop. You can also press the corresponding button (see screenshot) and upload your MolFile.</li>
                                    <li>You can save your molecule in any format you like. See the “save” button on the top of the Ketcher desktop.</li>
                                    <li>Despite Ketcher supports drawing “any atom” or adding generic group, currently, this will not work being uploaded to the OdanChem as a request. This option will be added later.</li>
                                </ul>
                                <img style={{ paddingTop: '2em', width: '400px' }} src='/manual/ketcher_help.gif' className='center-img' />
                        </Item2>

                        <Item2 style={{ textAlign: 'left', paddingTop: '1em', fontSize: 'medium' }}>
                            <p id='wdafpc'><span style={{ fontWeight: '600', fontStyle: 'Italic' }}>What data are available for particular compound?</span></p>
                            <div>If you press “search as drawn” button you see all information about particular molecule available in our database. There are three groups of information: NMR spectra, Features, and Associated publications.
                                    Section “NMR spectra” consists of tabs with NMR spectra registered on different types of magnetic nuclei. For example, in screenshot below 74 1H NMR and 59 13C NMR spectra are available for this compound. You can double-click on the spectrum string and see the graphical representation of the spectrum.</div>
                            <img style={{ paddingTop: '2em' }} src='/manual/exact_search.gif' className='center-img' />

                            Section “Features”  includes the following information: Melting point, Infrared spectra, optical rotation data, chiral GC, chiral HPLC and HRMS of the particular compound. There is no additional web-pages to handle these data.
                            <img style={{ paddingTop: '2em' }} src='/manual/section_f.gif' className='center-img' />
                            Section “Associated publications” is just a list of publications where the particular compound was mentioned in any role. Compound name as it was mentioned in the publication text is also provided.
                            <img style={{ paddingTop: '2em' }} src='/manual/section_p.gif' className='center-img' />
                        </Item2>

                        <Item2 style={{ textAlign: 'left', paddingTop: '1em', fontSize: 'medium' }}>
                            <p id='wicnftc'><span style={{ fontWeight: '600', fontStyle: 'Italic' }}>Why I can not find the compound?</span></p>
                                <div>There are four possible reasons and solutions:
                                    <ul>
                                        <li>Your molecule has too simple structure. We focus on the compounds with a moderate complexity. So you will not find toluene or acetone in our database. However, 4-bromotoluene is available.</li>
                                        <li>Your molecule is too large. We do not have large molecules like proteins. So, if your request contains more tha 250 atoms (including hydrogens), it does not fit our database.</li>
                                        <li>This compound/publication was not added to the database. We work on it.</li>
                                        <li>If you are using substructure search - try to turn on “Search by tautomers” flag in the results page. This may help.</li>
                                    </ul>
                                </div>
                        </Item2>


                        <Item2 style={{ textAlign: 'left', paddingTop: '1em', fontSize: 'medium' }}>
                            <p id='wirb'>
                                <span style={{ fontWeight: '600', fontStyle: 'Italic' }}>What is the Report error button?</span></p>
                                <div>The report error button accompanies all spectra and features provided for every substance. If you find out that this particular spectrum or feature does not correspond to the structure – press this button and we will get your report and correct this issue.</div>
                        </Item2>

                        <Item2 style={{ textAlign: 'left', paddingTop: '1em', fontSize: 'medium' }}>
                            <p style={{ fontWeight: '600', fontStyle: 'Italic' }}>What is Quality and Overall quality located along with NMR spectra and features?</p>
                            <div>All NMR spectra associated with the compound structure always have quality and overall quality marks, indicating the reliability of the compound characterization. The higher the quality mark – the more reliable the data are. Overall quality is a sum of qualities of all spectra provided in particular paper.
                                For example, authors report characterization of the compound and provide 1H NMR, 13C NMR and HRMS for it. If everything is ok, quality of 1H NMR will be 0.6, quality of 13C NMR will be 1, and quality of HRMS will be 1. In this case, overall quality of the characterization will be 1+1+0.6 = 2.6.
                                In another paper 1H NMR, 13C NMR, and HRMS might have qualities 0.5, 0, and 1 respectively. Overall quality is 1.5 in this case. This may indicate, for example, that authors did not mention several signals in 13C NMR.
                                Therefore, quality is a measure of consistency of the particular spectrum to particular structure, and overall quality is a measure of consistency of the whole characterization of the particular compound in particular paper.</div>
                        </Item2>

                        <Item2 style={{ textAlign: 'left', paddingTop: '1em', fontSize: 'medium' }}>
                            <p id='sso'>
                                <span style={{ fontWeight: '600', fontStyle: 'Italic' }}>Substructure search options</span></p>
                                <div>You can use substructure search by structure of compound. Once you press “SEARCH AS SUBSTRCTURE” button on the compound-search page you get the substructure search results and can filter them according to your particular request.</div>
                                <img style={{ paddingTop: '2em' }} src='/manual/sub_search.gif' className='center-img' />
                                <div>
                                    Using the “Extra filter” field you can open ketcher and draw an additional molecular fragment required in the molecule. Also you can choose several types of NMR spectra and properties that have to be associated with resulting molecules subset. If you do not see 	enough results – try to switch on the “Search by tautomers” flag and apply filters. It could extend results and show the desired molecule.
                                </div>
                        </Item2>

                        <Item2 style={{ textAlign: 'left', paddingTop: '1em', fontSize: 'medium' }}>
                            <p id='htuss'>
                                <span style={{ fontStyle: 'Italic' }}>How to use spectrum search?</span>
                            </p>
                            <div>General idea: our spectrum search implies that you upload the NMR spectrum and search for compounds that do not contradict to it. For example, if your 13C NMR spectrum does not contain signals at, for example, 150 ppm, you will not find spectra containing this signal. This idea is schematically represented by the following figure:</div>
                            <img src='/manual/spectra_table_help.gif' className='center-img-small' />
                        </Item2>

                        <Item2 style={{ textAlign: 'left', fontSize: 'medium' }}>
                            <div>There are three independent option show to upload your request.</div>
                            <img src='/manual/spectra_page.gif' className='center-img' />

                            <div style={{ paddingTop: '1em' }}>
                                <ol type='1'>
                                    <li>You can upload the source file of your spectrum. As browsers cannot handle folders, a ZIP archive of the folder with your spectrum is required. Just pack the spectrum folder from your instrument, drag it to the highlighted folder and press “submit”. All information will be extracted from the file, so it is not necessary to choose spectrum type and solvent. Note: the website will extract all peaks that are higher than 5% of the maximum peak height. Therefore, if your spectrum has a low signal to noise ratio, it is possible to get too many peaks. We recommend using high quality spectra for uploading or using spectrum string input.
                                        At this moment we can process Bruker and Varian files (1r file is necessary in the case of Bruker instrument). If you want to upload the file of another format or your file cannot be processed, please, inform us about it and send us the file. While we add the format of your spectrum, you can use another options to submit your spectrum.
                                    </li>
                                    <li>Spectrum string. Just paste the description of your spectrum to the corresponding field and press “submit”. The description should be in a classical format relevant for the different scientific papers. For example:
                                        1H NMR (400 MHz, CDCl3) 5.61–5.57 (m, 1H), 5.24 (d, J = 9.5 Hz, 1H), 4.11 (br s, 1H), 3.87 (dd, J = 10.7, 3.0 Hz, 1H), 3.54–3.46 (m, 1H), 3.39 (dd, J = 10.4, 7.8 Hz, 1H), 2.74–2.62 (m, 1H), 2.19–2.07 (m, 1H), 2.00–1.88 (m, 1H), 1.85–1.75 (m, 1H), 1.73 (d, J = 1.4 Hz, 3H), 1.62 (ddd, J = 2.4, 2.4, 1.2 Hz, 3H), 1.60–1.48 (m, 2H), 0.99 (d, J = 6.7 Hz, 3H), 0.93 (t, J = 7.3 Hz, 3H).
                                    </li>
                                    <li>Manual peak addition. You can manually fill the peak table. Choose one of 37 types of NMR spectra, choose the solvent and use the button “add peak” to add peak. If you want to add doublet – add two peaks. However, it seems to be more convenient to use spectrum string to insert the data.
                                    </li>
                                </ol>
                            </div>

                        </Item2>

                        <Item2 style={{ textAlign: 'left', paddingTop: '1em', fontSize: 'medium' }}>
                            <div>Finally, after you press the “submit” button you will get the following preview of your request:
                                <img src='/manual/spectra_page_result.gif' className='center-img' />
                                <div style={{ paddingTop: '1em' }}>Here you can finally edit your query. Here is possible to change solvent and add or remove peaks. In particular, it is possible to choose “Any” solvent. In this case the search will ignore the solvent for NMR.
                                </div>
                                <div>
                                    Removing peaks is helpful if you have interpreted a part of them. In this case just remove the assigned peaks and search for NMR spectra using other peaks.
                                    If you want to achieve stronger modifications (for example, change the nuclei, remove many signals, re-upload fid-file, and so on), press “edit spectrum” that bring you back to the previous page. If everything is ok on this page, press “spectrum search”.
                                </div>
                            </div>
                        </Item2>

                        <Item2 style={{ textAlign: 'left', paddingTop: '1em', fontSize: 'medium' }}>
                            <div>Spectrum search generally takes more time than structure search. You will get the table with the list of found spectra corresponding the request. They are ordered by similarity.
                                An example is provided below.
                                For example, you have the following 13C NMR spectrum:
                                “13C NMR (126 MHz, CDCl3) δ 165.8, 135.5, 135.2, 134.4, 131.9, 129.7, 128.9, 127.1, 120.5, 21.0.” You passed through spectrum upload and got the following results:
                                <img src='/manual/spectrum_preview.gif' className='center-img' />
                            </div>
                        </Item2>

                        <Item2 style={{ textAlign: 'left', paddingTop: '1em', fontSize: 'medium' }}>
                            <div>You see that these compounds cannot present in your sample. And based on your understanding of your chemistry you could suggest that all molecules in your spectrum should contain 4-methylaniline substructure. In this case you can use the substructure filter and draw 4-methylaniline. As a result you get only spectra that are associated with molecules containing this substructure:</div>
                            <img src='/manual/spectrum_final.gif' style={{ paddingTop: '1em' }} className='center-img' />
                            <div>Now this is correct result – the sample contained N-p-tolylbenzamide.</div>
                            <div>Double click on the spectrum string sends you to the page with comparison of the found spectrum and requested:</div>
                            <img src='/manual/spectrum_single.gif' style={{ paddingTop: '1em' }} className='center-img' />
                        </Item2>

                        <Item2 style={{ textAlign: 'left', paddingTop: '1em', fontSize: 'medium' }}>
                            <p id='wips'><span style={{ fontWeight: '600', fontStyle: 'Italic' }}>What is publication search?</span></p>
                                <div>This type of search is intended to show all compounds and NMR spectra described in this publication. Just insert doi of the desired paper and press “SEARCH”.
                                </div>
                                <img src='/manual/publication_search.gif' style={{ paddingTop: '1em' }} className='center-img' />

                                <div>The resulting table shows you compounds found here and available NMR spectra.</div>
                                <img src='/manual/publication_result.gif' style={{ paddingTop: '1em' }} className='center-img' />
                        </Item2>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
);
}
export default HelpPage;
