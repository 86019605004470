import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import CompoundPropertyComponent from './CompoundPropertyComponent';
import ITabPanelProps from './ITabPanelProps';
import translate from '../services/Translation';


type ICompoundProps = { compoundId?: string, propertiesCount: Record<string, number> };

const CompoundPropertiesComponent: React.FC<ICompoundProps> = (props) => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

    const header = (<div className='container' style={{ height: '2em', alignItems: 'center' }}>
        <span>Features</span></div>);


    const panel = (propType: string, index: number) => (<TabPanel key={index} value={value} index={index}>
        <CompoundPropertyComponent compoundId={props.compoundId} property={propType} />
    </TabPanel>);


    const tab = (propType: string, count: number, position: number) => (<Tab key={position} label={translate(propType) + '(' + count + ')'} {...a11yProps(position)} />);


    return (<div>
        {header}
        <Tabs value={value} style={{ padding: '0px' }} onChange={handleChange} aria-label="basic tabs example">
            {Object.entries(props.propertiesCount).filter(([k, v]) => v !== 0).map(([key, value], index) => {
                return (tab(key, value, index))
            })} </Tabs>

        {Object.entries(props.propertiesCount).filter(([k, v]) => v !== 0).map(([key, value], index) => {
            return panel(key, index)
        })}
    </div>);
};
export default CompoundPropertiesComponent;

function TabPanel(props: ITabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

