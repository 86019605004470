import React from 'react';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { TextField } from '@mui/material';
import ForwardIcon from '@mui/icons-material/Forward';
import IconButton from '@mui/material/IconButton';

import AppSettings from '../../API/appSettings';
import { Item, Item2 } from '../../styles';
import '../../App.css';
import ISubSearchFilterModel from '../../schemas/ISubSearchFilterModel';
import MoleculeView from '../../components/MoleculeView';
import Header from '../../components/HeaderComponent';
import HelpIconWithHint from '../../components/HelpIconWithHint';
import { nameToMolecule } from '../../services/NameToMolecule';

type CompoundSearchProps = { history: any };
type CompoundSearchState = {
  isInProgress: boolean,
  svgContent: string,
  isMoleculeInContainer: boolean,
  moleculeString: string,
  subSearchFilterModel?: ISubSearchFilterModel,
  queryText: string,
};

const useMolecule: string = 'main';

class CompoundSearch extends React.PureComponent<CompoundSearchProps, CompoundSearchState> {
  constructor(props: CompoundSearchProps) {
    super(props);
    this.state = {
      isInProgress: false,
      svgContent: '',
      isMoleculeInContainer: false,
      moleculeString: '',
      subSearchFilterModel: undefined,
      queryText: '',
    };
  }


  componentDidMount() {
    this.drawMoleculeInContainer();
  }


  drawMoleculeInContainer() {
    let moleculeString = this.getMolecule(useMolecule);
    if (moleculeString) {
      let svgContent = this.getSVG(useMolecule);
      this.setState({
        svgContent: svgContent,
        isMoleculeInContainer: true,
        moleculeString: moleculeString
      }, () => { });
    }
  }


  getMolecule(moleculeName: string): string {
    return localStorage.getItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.MAIN) || '';
  }


  getSVG(moleculeName: string): string {
    return localStorage.getItem(AppSettings.LOCAL_STORAGE_SVG_KEY + AppSettings.MAIN) || '';
  }


  renderOnlySubstructButtons() {
    return (<Button disabled={!this.state.isMoleculeInContainer} variant="contained" >Search as substructure</Button>);
  }


  renderSubstructButtons() {
    if (this.state.isMoleculeInContainer)
      return (<><Link to={{
        pathname: '/sub-search/1', state: {
          svgContent: this.state.svgContent,
          moleculeString: this.state.moleculeString,
        }
      }}
        style={{ textDecoration: 'none' }}>
        <Tooltip arrow title='Substructure search with filters applied'>
          {this.renderOnlySubstructButtons()}
        </Tooltip>

      </Link>
        <HelpIconWithHint title={''}
          text={'Substructure search with filters applied.'}
          handleOpen={() => { }} />

      </>);
    else return this.renderOnlySubstructButtons()
  }


  renderOnlyStructButtons() {
    return (<Button variant='contained' disabled={!this.state.isMoleculeInContainer} >Search as drawn</Button>);
  }


  useStyles = {
    arrow: {
      "&:before": {
        border: "1px solid #E6E8ED"
      },
    },
    tooltip: {
      backgroundColor: 'grey',
      border: "1px solid #E6E8ED",
      color: "#4A4A4A"
    }
  };


  filterChanges = (subSearchFilterModel: ISubSearchFilterModel) => {
    this.setState({ subSearchFilterModel: subSearchFilterModel });
  }


  renderStructButtons() {
    if (this.state.isMoleculeInContainer)
      return (<><Link to={{
        pathname: "/exact-search", state: {
          svgContent: this.state.svgContent,
          moleculeString: this.state.moleculeString
        }
      }}
        style={{ textDecoration: 'none' }}>
        <Tooltip arrow title='Exact search'>
          {this.renderOnlyStructButtons()}
        </Tooltip>
      </Link>
        <HelpIconWithHint title={''} text={'Search exactly this structure. Filters are not applied.'} handleOpen={() => { }} />
      </>);
    else return this.renderOnlyStructButtons()
  }

  
  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.convertToMolfile();
    }
  }


  async convertToMolfile() {
    if (!this.state.queryText) return;
    const molecule = await nameToMolecule(this.state.queryText);
    if (!molecule) return;
    localStorage.setItem(AppSettings.LOCAL_STORAGE_MOL_KEY + AppSettings.MAIN, molecule.molfile);
    localStorage.setItem(AppSettings.LOCAL_STORAGE_SVG_KEY + AppSettings.MAIN, molecule.svg);
    this.setState(prev => ({ ...prev, moleculeString: molecule.molfile,
                                      svgContent: molecule.svg,
                                      queryText: '',
                                      isMoleculeInContainer: true }));
  }


  render() {
    return (
      <Grid container spacing={0} className='main-frame'>
        <Grid xs={12}>
          <Item2><div style={{ height: '2em' }}></div></Item2>
        </Grid>
        <Header title='Compound Search' helpAddress='help#wics' />
        <Grid container md={12} style={{ marginTop: '4em' }} >
          <Grid xs={12} md={4} sm={12}>
          </Grid>
          <Grid xs={12} md={4} sm={12} textAlign='center' style={{ justifyContent: 'center' }}>
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <Item style={{ width: '240px' }}>
                <MoleculeView svgContent={this.state.svgContent}
                  link={'/editor/main'}
                  isMoleculeInContainer={this.state.isMoleculeInContainer}
                  moleculeString={this.state.moleculeString}
                  />
              </Item>
            </div>
            <div className='text-container' style={{ marginTop: '1em' }}>
              <Grid container>
                <Grid xs={12} textAlign='center'>
                  <TextField className='molecule-text'
                    type="text"
                    value={this.state.queryText}
                    sx={{
                      input: {
                        "&::placeholder": {
                          opacity: .5,
                        },
                      },
                      label: { color: 'blue' }
                    }}
                    onChange={(c) => {
                      this.setState(prev => ({ ...prev, queryText: c.target.value }))
                    }}
                    onKeyDown={this.onKeyDown}
                    helperText="ex. 1517-69-7"
                    style={{ width: '100%' }}
                    placeholder='Paste CAS, IUPAC, SMILES ' />
                </Grid>
                <Grid xs={0} style={{}}>
                  <IconButton onClick={() => { this.convertToMolfile() }} style={{ padding: '0px' }}>
                    <ForwardIcon style={{ verticalAlign: 'middle', fontSize: '2.4em', marginLeft: '-1em', cursor: 'pointer', zIndex: 999 }}
                      fontSize="large" color="primary" /></IconButton>
                </Grid>
              </Grid>
            </div>

          </Grid>
          <Grid xs={12} md={4} sm={12} textAlign='center'>
          </Grid>

        </Grid>
        <Grid xs={12} style={{ marginTop: '4em' }}>
        </Grid>

        <Grid md={3}>
          <Item2></Item2>
        </Grid>
        <Grid xs={12} md={3}>
          <Item2 style={{ height: '2.6em', marginBottom: '.5em' }}>
            {this.renderStructButtons()}
          </Item2>
        </Grid>
        <Grid md={6} xs={12} container>
          <Grid md={6} xs={12}>
            <Item2 style={{ height: '2.6em' }}>
              {this.renderSubstructButtons()}
            </Item2>
          </Grid>

          <Grid md={6}>
            <Item2 style={{ height: '2.6em', width: '100%', alignContent: 'center' }}>
            </Item2>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
export default CompoundSearch;
