import { createTheme, Theme } from "@mui/material/styles";

const theme: Theme = createTheme({
    typography: {
      fontFamily: "Open Sans",
    },
    
    spacing: 4,
    
    palette: {
      primary: {
        main: '#1765b3',
      },
      secondary: {
        main: '#337070',
      },
    },
  

    components: {
      
      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": {
            fontFamily: "Open Sans",
          },
          link: {
            //textDecoration: 'dotted'
          },
          body: {
            fontSize: "3rem",
            color: "purple",
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            //fontSize: '.9rem',
            ...( ownerState.variant === 'contained' && {
            '&:hover': {
              backgroundColor: '#206bb5',
            } } ) ,
            width: '240px',
            fontWeight: '600',
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'primary' && {
                color: '#fff',
              }),
          }),
      },
    },
  }});

  export default theme;