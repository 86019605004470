function translate(key: string): string {
    const translation: Record<string, string> = {
        'i_r': 'IR spectrum',
        'hr_me': 'HRMS',
        'm_p': 'Melting point',
        'hp_lc': 'HPLC',
        'chiral_gc': 'Chiral GC',
        'opt_rotation': 'Optical rotation',
    }
    const translated = translation[key.toLowerCase()];
    return translated === undefined ? key.replace('_',' ') : translated;
}
export default translate;