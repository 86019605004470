import React from 'react';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Unstable_Grid2';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { fetchGet } from '../services/GettingData';
import PublicationModel from '../schemas/Publication/IPublicationModel'
import Doi from './Doi';

type ICompoundProps = { compoundId?: string };

export const PublicationsComponent: React.FC<ICompoundProps> = (props) => {

    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [totalPublications, setTotalPublications] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(10);
    const [publications, setPublications] = React.useState<PublicationModel[]>([]);

    const columns: GridColDef[] = [
        {
          field: 'id',
          headerName: 'id',
          width: 0,
          hide: true
        },
        {
          field: 'urn',
          headerName: 'Reference',
          width: 250,
          flex: 0.8,
          disableColumnMenu: true,
          sortable: false,
          renderCell: (params) => {
            return Doi.Doi(params.row.urn, 
                           params.row.authors.replaceAll(';','; ')
                                                             .replaceAll(';  ','; ') 
                                                             + ', ' + params.row.title 
                                                             + ', ' + params.row.journal 
                                                             + ', ' + params.row.year, 
                          params.row.volume_info, 
                          params.row.pages) 
          }
        },
        {        
          field: 'compound_representation',
          headerName: 'Compound name',
          disableColumnMenu: true,
          flex: 0.2,
        },
      ];

      
    const pageChangeHandle = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    }

    
    React.useEffect(() => {
        const fetchData = async () => {
            if (props.compoundId) {
                const url = '/publication/compound/' + props.compoundId + '?page=' + currentPage + '&size=' + pageSize;
                const response = await fetchGet(url);
                const json = await response.json();
                setTotalPublications(json.total);
                setPublications(json.items);
            }
        }
        fetchData()
            .catch(console.error);
    }, [currentPage]);

    
    return (<Box sx={{ minWidth: 120, marginTop: '1em' }}>
        <DataGrid
            getRowHeight={() => 'auto'}
            autoHeight
            onCellDoubleClick={(params, event) => {
              if (!event.ctrlKey) {
                event.defaultMuiPrevented = true;
              }
              if (params.field === 'urn') Doi.gotoDoi(params.row.urn);
            }}
            rows={publications}
            columns={columns}
            disableSelectionOnClick
          />

        <div style={{ marginTop: '1em' }}>
            <Pagination count={Math.ceil(totalPublications / pageSize)} page={currentPage} onChange={pageChangeHandle} />
        </div>
    </Box>
    );
}
export default PublicationsComponent;