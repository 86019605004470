import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';


function PrivacyPolicyPage() {

  return (<Grid container spacing={0} className='main-frame'>
    <Grid xs={12} style={{ marginTop: '3em', fontWeight: '600' }} >
      OdanChem privacy policy
    </Grid>
    <Grid xs={12} style={{ marginTop: '.5em' }} >
      This privacy policy sets out how OdanChem Team uses and protects any information that you give us when you use this website. We use the whole information you provide us only in accordance with this policy statements.
    </Grid>
    <Grid xs={12}>
      OdanChem Team may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. If you were registered in our system, we inform you by email in case of changing of this privacy policy.

    </Grid>
    <Grid xs={12} style={{ marginTop: '2em', fontWeight: '600' }} >
      What we collect upon registration:
      <Grid xs={12} style={{ marginTop: '.5em', fontWeight: '400' }} >
        <ul>
          <li>Your name</li>
          <li>Your job title and organization title</li>
          <li>Your contact information including email address</li>
        </ul>
      </Grid>
    </Grid>

    <Grid xs={12} style={{ marginTop: '2em', fontWeight: '600' }} >
      What we do with the information we gather:
    </Grid>
    <Grid xs={12} style={{ marginTop: '.5em' }}>

      We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
      <ul>
        <li>Internal record keeping</li>
        <li>Counting of user sessions to optimize the website performance.</li>
        <li>We may use the information to improve our products and services</li>
        <li>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
      </ul>

      <p>
        We do not disclose your personal data to third parties unless we have your permission or are required by law to do so. You can delete your personal data from our system by clicking “REMOVE ACCOUNT” in the “personal information” section or via sending us email to contacts@odanchem.org .
      </p>

      <Grid xs={12} style={{ marginTop: '2em', fontWeight: '600' }} >
        Security:
      </Grid>
    </Grid>
    <Grid xs={12} style={{ marginTop: '.5em' }} >
      We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
    </Grid>

    <Grid xs={12} style={{ marginTop: '2em', fontWeight: '600' }} >
      Links to other websites:
    </Grid>

    <Grid xs={12} style={{ marginTop: '.5em' }} >
      Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.
    </Grid>
  </Grid>);
}

export default PrivacyPolicyPage;