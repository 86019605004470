
function getPropertyTypes(): Record<string, string> {
  return {
    'IR SPECTRUM': 'i_r',
    'MELTING POINT': 'm_p',
    'HRMS': 'hrms',
    'HPLC': "hp_lc",
    'Chiral GC': 'chiral_gc',
    'Optical rotation': 'opt_rotation',
  }
}

export { getPropertyTypes };